<template>
  <b-card>
    <g-table
      ref="units-table"
      :items="items"
      :columns="tableColumns"
      :noAction="true"
      perPage="25"
      :totalRows="totalRows"
      :createButton="{ visiable: true }"
      :searchInput="{ visiable: true }"
      @on-create="
        (v) => {
          openModal({
            englishName: '',
          });
        }
      "
    >
      <template #actions="{ item }">
        <div class="text-nowrap">
          <b-button
            v-b-tooltip.hover.top="$t('edit')"
            v-permission=" 'editItemCategories'"
            data-action-type="edit"
            variant="flat-secondary"
            class="btn-icon"
            size="sm"
            @click="openModal(item)"
          >
            <feather-icon
              :id="`itemCategory-row-${item.id}-preview-icon`"
              icon="EditIcon"
            />
          </b-button>
          <b-button
            v-b-tooltip.hover.top="$t('delete')"
            v-permission=" 'deleteItemCategories' "
            data-action-type="delete"
            variant="flat-danger"
            class="btn-icon"
            size="sm"
            @click="remove(item)"
          >
            <feather-icon
              :id="`itemCategory-row-${item.id}-delete-icon`"
              icon="TrashIcon"
              stroke="red"
              class="danger"
            />
          </b-button>
        </div>
      </template>
    </g-table>
    <b-modal
      ref="edit-modal"
      no-close-on-backdrop
      cancel-variant="outline-secondary"
      centered
      size="lg"
      hide-footer
      :title="selectedItem.id > 0 ? $t('editItemCategory') : $t('newItemCategory')"
      @hidden="
        () => {
          selectedItem = {};
        }
      "
    >
      <g-form @submit="save">
        <b-row>
          <b-col
            cols="12"
            class="d-flex justify-content-center mb-2"
          >
            <!-- media -->
            <b-media no-body>
              <b-media-aside>
                <b-link>
                  <b-img
                    ref="previewEl"
                    rounded
                    height="80"
                    width="80"
                    :src="url"
                  />
                </b-link>
                <!--/ avatar -->
              </b-media-aside>

              <b-media-body class="mt-75 ml-75">
                <!-- upload button -->
                <b-button
                  variant="primary"
                  size="sm"
                  class="mb-75 mr-75"
                  @click="$refs.imgupload.$el.click()"
                >
                  {{ $t('change') }}
                </b-button>
                <b-form-file
                  ref="imgupload"
                  accept=".jpg, .png, .gif"
                  :hidden="true"
                  plain
                  @change="onFileChanged"
                />
                <!--/ upload button -->
              </b-media-body>
            </b-media>
            <!--/ media -->
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <!-- code  -->
            <g-field
              id="code"
              type="number"
              :value.sync="selectedItem.code"
              name="code"
              label-text="code"
              disabled
              :rules="`min_value:1|${selectedItem.code ? 'integer': ''}` "
            />
          </b-col>

          <b-col md="6">
            <!-- arabicName  -->
            <g-field
              id="arabicName"
              :value.sync="selectedItem.arabicName"
              :rules="`${selectedItem.arabicName ? '' : 'required'}`"
              name="arabicName"
              label-text="arabicName"
            />
          </b-col>

          <b-col md="6">
            <!-- englishName  -->
            <g-field
              id="englishName"
              ref="englishName"
              :value.sync="selectedItem.englishName"
              name="englishName"
              label-text="englishName"
            />
          </b-col>

          <!-- accountNumber -->
          <b-col md="6">
            <g-field
              :value.sync="selectedItem.parentCategoryId"
              :dir="isRight ? 'rtl' : 'ltr'"
              field="select"
              name="parentCategoryId"
              :options="items"
              label="arabicName"
              label-text="bigCategory"
            />
          </b-col>

          <!-- <b-col
            v-if="!this.profile.isInvoicePlan"
            md="2"
          >
            <label
              style="font-size: 14px;margin-bottom: 7px;"
              for="isForResturants"
            >
              {{ $t('isForResturant') }}
            </label>
            <b-form-group>
              <b-form-checkbox
                v-model="selectedItem.isForResturants"
                class="custom-control-primary"
              />
            </b-form-group>
          </b-col> -->
        </b-row>

        <b-row>
          <!-- notes -->
          <b-col md="12">
            <b-form-group :label="this.$t('notes')">
              <b-form-textarea
                id="textarea"
                v-model="selectedItem.notes"
                label="Notes"
                rows="3"
                max-rows="6"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
            class="d-flex justify-content-end mt-1"
          >
            <b-button
              v-permission="$route.meta.permission"
              class="mr-1"
              type="submit"
              variant="primary"
              data-action-type="save"
            >
              {{ $t('save') }}
            </b-button>
            <b-button
              variant="secondary"
              @click="closeModal"
            >
              {{ $t('Close') }}
            </b-button>
          </b-col>
        </b-row>
      </g-form>
    </b-modal>
  </b-card>
</template>

<script>
import GTable from '@/pages/Shared/Table.vue';

export default {
  components: {
    GTable,
  },
  data() {
    return {
      items: [],
      totalRows: 0,
      sortBy: 'id',
      isSortDirDesc: true,
      currentPage: 1,
      perPage: 25,
      selectedItem: {},
      modal: false,
      searchQuery: '',
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      Accounts: [],
      url: '',
    };
  },
  computed: {
    tableColumns() {
      return [
        { key: 'code', label: this.$t('code'), sortable: true },
        { key: 'arabicName', label: this.$t('categoryName'), sortable: true },
        { key: 'actions' },
      ];
    },
  },
  watch: {
    'selectedItem.code'(newVal) {
      if (Number.isNaN(newVal)) {
       delete this.selectedItem.code
      }
    }
  },
  mounted() {
    this.getItems();
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getItems() {
      this.get({ url: 'ItemCategories' }).then((data) => {
        this.items = data;
        this.totalRows = data.length
      });
    },
    save() {
      if (this.selectedItem.id > 0) {
        this.update({
          url: 'ItemCategories',
          data: this.selectedItem,
          id: this.selectedItem.id,
        }).then(() => {
          this.closeModal();
          this.doneAlert({ text: this.$t('updatedSuccessfully') });
          this.getItems();
        });
      } else {
        this.create({ url: 'ItemCategories', data: this.selectedItem }).then(
          () => {
            this.closeModal();
            this.doneAlert({ text: this.$t('savedSuccessfully') });
            this.getItems();
          }
        );
      }
    },
    onFileChanged(event) {
      const file = event.target.files[0];
      this.selectedItem.imageExtension = file.name.split('.').pop();
      this.toBase64(file).then((file1) => {
        this.selectedItem.base64Image = file1.split(',').pop();
      });
      this.url = URL.createObjectURL(file);
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    remove(item) {
      this.confirmAction(
        {
          text: this.$t('areYouSureYouWantToDelete'),
        },
        () => {
          this.delete({ url: 'ItemCategories', id: item.id }).then(() => {
            this.doneAlert({ text: this.$t('deletedSuccessfully') });
            this.getItems();
          });
        }
      );
    },
    closeModal() {
      this.$refs['edit-modal'].hide();
    },
    openModal(item) {
      this.selectedItem = { ...item };
      this.url = this.selectedItem.imageUrl
        ? `${this.baseUrl}${this.selectedItem.imageUrl}`
        : 'item_default_image.jpg';
      this.$refs['edit-modal'].show();
    },
  },
};
</script>

<style></style>
